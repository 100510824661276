export const TKeys = {
    appName: "app_name",
    unknownError: "unknown_error",
    menuRestricted: "menu_restricted",
    retry: "retry",
    search: "search",
    ok: "ok",
    welcome: "welcome",
    loginRegisterMessage: "login_register_message",
    password: "password",
    login: "login",
    username: "username",
    noInternetConnection: "no_internet_connection",
    invalidPassword: "invalid_password",
    accountExpired: "account_expired",
    accountNotFound: "account_not_found",
    messageHome: "message_home",
    versionPlaceholder: "version_placeholder",
    logout: "logout",
    logoutSubtitle: "logout_subtitle",
    faq: "faq",
    subtitleFaq: "subtitle_faq",
    aboutUs: "about_us",
    subtitleAboutUs: "subtitle_about_us",
    yes: "yes",
    no: "no",
    logoutMessage: "logout_message",
    categoryRestricted: "category_restricted",
    askAdminResetDevice: "ask_admin_reset_device",
    loginExpired: "login_expired",
    rememberMe: "remember_me",
    weight: "weight",
    height: "height",
    active: "active",
    veryActive: "very_active",
    lessActive: "less_active",
    age: "age",
    cm: "cm",
    kg: "kg",
    selectGender: "select_gender",
    underweight: "underweight",
    normal: "normal",
    overweight: "overweight",
    difference: "difference",
    obeseThree: "obese_three",
    obeseTwo: "obese_two",
    obeseOne: "obese_one",
    less_16: "less_16",
    verySeverlyUnderweight: "very_severly_underweight",
    severlyUnderweight: "severly_underweight",
    _16_0_16_9: "_16_0_16_9",
    _17_0_18_4: "_17_0_18_4",
    _18_5_24_9: "_18_5_24_9",
    _25_0_29_9: "_25_0_29_9",
    _30_34_9: "_30_34_9",
    _35_39_9: "_35_39_9",
    _40: "_40",
    normalWeight: "normal_weight",
    plusString: "plus_string",
    minusString: "minus_string",
    noramlWeightText: "noraml_weight_text",
    bmi: "bmi",
    bmiSubtitle: "bmi_subtitle",
    notiCategoryChannel: "noti_category_channel",
    notiVideoChannel: "noti_video_channel",
    notiOtherChannel: "noti_other_channel",
    notifications: "notifications",
    notificationsSubtitle: "notifications_subtitle",
    homeTab: "home_tab",
    sportTab: "sport_tab",
    foodTab: "food_tab",
    moreTab: "more_tab",
    featuredVideos: "featured_videos",
    featuredImages: "featured_images",
    featuredCategories: "featured_categories",
    bodyTab: "body_tab",
};