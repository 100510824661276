import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import KetchupView from "./KetchupView";
import ImageViewer from "../detail/ImageViewer";
import VideoPlayer from "../detail/VideoPlayer";
import {useNavigate} from "react-router-dom";
import PdfViewer from "../detail/PdfViewer";
import TextViewer from "../detail/TextViewer";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: '60px',
    },
    list: {
        padding: "8px",
    }
}));

export default function DataListView({list}) {
    const classes = useStyles();
    const [image, setImage] = useState();
    const [video, setVideo] = useState();
    const [pdf, setPdf] = useState();
    const [text, setText] = useState();
    const navigate = useNavigate()
    const onClick = item => {
        if (item.data) {
            let data = item.data;
            if (data.hasOwnProperty("preview")) {
                setImage(data)
            } else if (data.hasOwnProperty('parentId')) {
                navigate(`/categories/${data.id}`, {state: data});
            } else if (item.pdf) {
                setPdf(item.pdf);
            } else if (item.text) {
                setText(item.text)
            } else {
                setVideo(data)
            }
        }
    }
    return (
        <React.Fragment>
            <List className={classes.root}>
                {list.map(it => (
                    <ListItem key={it.id}
                              className={classes.list}
                              onClick={() => onClick(it)}
                              button>
                        <KetchupView item={it}/>
                    </ListItem>
                ))}
            </List>
            {image && <ImageViewer open={true} handleClose={() => setImage(null)}
                                   image={image}/>}
            {video && <VideoPlayer open={true} handleClose={() => setVideo(null)}
                                   video={video}/>}
            {pdf && <PdfViewer open={true} handleClose={() => setPdf(null)}
                               pdf={pdf}/>}
            {text && <TextViewer open={true} text={text} handleClose={() => setText(null)}/>}
        </React.Fragment>
    );
}