import React from "react";
import logo from '../../assets/logo.png';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {TKeys} from "../../i18n/keys";

const useStyle = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        maxWidth: 700,
        textAlign: "center",
        margin: "0 auto",
    },
    logo: {
        width: 180,
        height: 40,
        margin: '0 auto'
    },
    text: {
        fontSize: 'normal',
        padding: theme.spacing(1)
    }
}));
const AboutText = ({className}) => <div className={className}>
    <p>Şubat 1987 tarihinde Konyada doğdum. Lise eğitimimi Elazığ Ahmet Kabaklı Anadolu
        Öğretmen Lisesinde yoğunluklu olarak formasyon yani eğitim bilgisi ve
        öğretme eğitimleri alarak, üniversite eğitimimi de Dokuz Eylül
        Üniversitesi Biyoloji Öğretmenliği bölümünden yine
        yoğunluklu olarak formasyon dersleri alarak tamamladım.<br/>
        Üniversite öğrenciliği döneminde fitness sporuna başlamış ve malesef spor salonlarının acı
        durumunu, eğitimsiz spor salonu sahiplerini ve antrenörlerini acı bir şekilde tecrübe edinmiş,
        aynı zamanda da kendi hatalarımın da kurbanı olmuş ve malasef şansızlığım sebebi
        ile yıllarca kulak burun boğaz alerji sorunları ile uğraşmış, o dönemde bağırsaklarında
        fizyolojik problemlerinin olduğunu bilmeyen, ne kadar kaliteli antrenman yaparsa
        o kadar kas kaybeden, sonuç alamadıkça hırsının da kurbanı olan bir bireydim.
        Spor salonlarındaki yanlış bilgilere sahip bireylerin de katkısıyla, sonuç alamadığı
        gibi sağlığını daha fazla bozmuş yıllar sonra Türkiye Vucut Geliştirme ve
        Fitness Federasyonundan 3 kez eğitim almış (eğitim düzeyini artırmak için
        belge alamayacağını bilsede kurslara katılmış) 2. Kademe Fitness
        eğitmenliği sertifikası olan bir eğitmenim şimdi.
        İnsan, bitki, hayvan anatomisi ve fizyolojisi derslerini 3 sene boyunca
        üniversitede almış olmam, bedenimizin nasıl çalıştığı konusunda
        yıllarca kafa yorup araştırmalar yapıp, kitaplar okuyan ve sorgulayan
        bir birey olmam, federasyondan aldığım eğitimlede birlikte, kendi sağlık sorunlarımı düzeltmeye çalışmamın
        verdiği zorunluluk ve avantajla; 2017 yılında aktif olarak spor salonu eğitmenliğine başladım, 2019 yılı
        başından itaibaren de sosyal medya üzerinden, coğunluğu ile yüz yüze bile tanışma fırsatı bulamadan,
        danışanlarımın hayatını değiştirme fırsatı buldum.
        Bu süreçte sizlere elimden geldiğince en kolay ve en doğru yolu sunmaya ve
        çalışmaya devam edeceğim. Kendisi yıllarca sağlık sorunları ile uğraşmış
        birisi olarak benim için her zaman en büyük önceliğiniz beden sağlığınız,
        sonrasında mümkün olan en kolay ve en hızlı şekilde sonuçlar almanızı
        sağlamak olacaktır. Benimle çalışmayı tercih ettiğiniz ve kolay yolu değil
        doğru yolu yani bedeninizi anlamayı seçtiğiniz için teşekkürlerimi sunuyorum.
        <br/><b>İletişim</b><br/>
        Whatsapp ve telefon <a href="tel:05525790787">05525790787</a><br/>
        E-Mail <a href="mailto:bugrahocafityasam@gmail.com">bugrahocafityasam@gmail.com</a><br/>
        İnstagram - <a href="https://instagram.com/bugrahocafityasam" target="_blank"
                       rel="noopener noreferrer">https://instagram.com/bugrahocafityasam</a><br/>
        Youtube - <a href="https://www.youtube.com/channel/UCc7Y-MW7uS0mG9u4Na_r-gA" target="_blank"
                     rel="noopener noreferrer">https://www.youtube.com/channel/UCc7Y-MW7uS0mG9u4Na_r-gA</a>
    </p>
</div>
export default function AboutUsView() {
    const {t} = useTranslation();
    const classes = useStyle();
    return (<div className={classes.root}>
            <img src={logo} alt={t(TKeys.appName)}
                 className={classes.logo}/>
            <br/>
            <br/>
            <br/>
            <AboutText className={classes.text}/>
            <br/>
        </div>
    )
}