import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import {homeData} from "../../services/AppService";
import DataListView from "./DataListView";
import {TKeys} from "../../i18n/keys";
import {useNavigate} from "react-router-dom";
import {logout} from "../../services/LoginApi";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function HomeDataView({type, ...props}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("");
    const [login, setLogin] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true);
        homeData(type, list => {
            setLoading(false);
            setData(list);
        }, (error, login) => {
            setLoading(false);
            setLogin(login);
            setMessage(error);
        }, t)
    }, [t, type])
    const handleErrorTap = () => {
        if (login) {
            logout()
            navigate("/login")
        }
        setMessage("");
    }
    return (
        <div className={classes.root}>
            {loading && <CircularProgress/>}
            {!loading && <DataListView list={data} {...props}/>}
            <Dialog open={message.length > 1}
                    onClose={() => setMessage("")}>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={handleErrorTap}>{t(TKeys.ok)}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}