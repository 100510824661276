import React, {useState} from "react";
import ProfileView from "./ProfileView";
import MenuListView from "./MenuListView";
import LogoutDialog from "./LogoutDialog";
import {useNavigate} from "react-router-dom";

export default function MoreView() {
    const [id, setId] = useState("")
    const navigate = useNavigate()
    const redirectToLogin = () => {
        navigate("/login")
    }
    return (
        <div>
            <ProfileView/>
            <MenuListView onChange={action => {
                if (action === "about") {
                    navigate("/about");
                    return;
                } else if (action === "faqs") {
                    navigate("/faqs");
                    return;
                } else if (action === "notifications") {
                    navigate("/notifications")
                    return;
                }
                setId(action);
            }}/>
            <LogoutDialog open={id === "logout"}
                          onClose={() => setId("")}
                          onLogout={redirectToLogin}/>
        </div>
    )
}