import React from "react";
import BottomNavigationView from "./BottomNavigationView";
import {makeStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";
import MoreView from "../more/MoreView";
import HomeDataView from "../data/HomeDataView";
import {Types} from "../../util/DataType";
import {useNavigate} from "react-router-dom";
import {isSigned} from "../../services/LoginApi";

const useStyles = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        flexDirection: 'column',
        minHeight: '100vh',
        display: 'flex',
        margin: '0 auto'
    },
    content: {
        marginBottom: '60px',
        minHeight: '100vh',
    },
    bottomBar: {
        margin: '0 auto',
        borderRadius: theme.spacing(2),
        bottom: 0,
        width: '100%',
        position: 'fixed',
    },
}));

function getSessionTab() {
    let stored = sessionStorage.getItem("tab");
    if (stored == null) {
        return 0;
    }
    return Number(stored);
}

export default function Home({...props}) {
    const classes = useStyles();
    const [tab, setTab] = React.useState(getSessionTab);
    const navigate = useNavigate()
    if (!isSigned()) {
        navigate("/login")
    }
    const onTabChange = index => {
        setTab(index);
        sessionStorage.setItem("tab", `${index}`);
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                {tab === 0 && <HomeDataView type={Types.home} {...props}/>}
                {tab === 1 && <HomeDataView type={Types.fitness} {...props}/>}
                {tab === 2 && <HomeDataView type={Types.body} {...props}/>}
                {tab === 3 && <HomeDataView type={Types.food} {...props}/>}
                {tab === 4 && <MoreView {...props}/>}

            </div>
            <Paper className={classes.bottomBar}>
                <BottomNavigationView
                    value={tab}
                    onChange={onTabChange}/>
            </Paper>
        </div>
    )
}