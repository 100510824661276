import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./i18n/i18n";
import axios from "axios";
import {accessToken} from "./services/LoginApi";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "https://fityasambh.com:8443/fitV2"
if (accessToken()) {
    axios.defaults.headers['Authorization'] = `Bearer ${accessToken()}`
}
ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
