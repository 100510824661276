import React from "react";
import logo from '../../assets/logo.png';
import {useTranslation} from "react-i18next";
import {makeStyles, Typography} from "@material-ui/core";
import {TKeys} from "../../i18n/keys";

const useStyle = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        maxWidth: 700,
        padding: "1em",
        textAlign: "center",
        margin: "0 auto",
    },
    logo: {
        width: 180,
        height: 40,
        margin: '0 auto'
    },
    text: {
        fontSize: 'normal',
    },
    title: {
        fontWeight: 'normal',
        fontSize: '24px',
        width: '100%',
        marginTop: theme.spacing(1),
    },

    subtitle: {
        width: '100%',
        fontSize: 'small',
        color: "black"
    },
    image: {
        width: 200,
        height: 150,
        borderRadius: 10,
    }

}));
export default function NotificationDetailView({notification}) {
    const {t} = useTranslation();
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <img src={notification.image ? notification.image : logo}
                 alt={t(TKeys.appName)}
                 className={notification.image ? classes.image : classes.logo}/>
            <Typography color={'textPrimary'} className={classes.title}>
                {notification.subject}
            </Typography>
            <Typography color={'textSecondary'} className={classes.subtitle}>
                {notification.body}
            </Typography>
            <Typography color={'textSecondary'}>
                {new Date(notification.time).toLocaleString()}
            </Typography>
        </div>
    )
}