import React from "react";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        borderRadius: '10px',
        height: '190px',
        background: "black",
    },
    thumb: {
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        objectFit: 'cover',
        opacity: 0.9,
    },

    textContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        textAlign: 'center',
    },
    text: {
        fontWeight: 'normal',
        fontSize: '24px',
        width: '100%',
        color: "white",
    }

}))
export default function KetchupView({item}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img src={item.image} alt={item.name} className={classes.thumb}/>
            <div className={classes.textContainer}>
                <Typography className={classes.text}>
                    {item.name}
                </Typography>
            </div>
        </div>
    )
}