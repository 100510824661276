import React from "react";
import {AppBar, IconButton, makeStyles, Toolbar, Typography} from "@material-ui/core";
import BackIcon from '@material-ui/icons/ArrowBack';
import AboutUsView from "../more/AboutUsView";
import FaqListView from "../more/faq/FaqListView";
import NotificationsListView from "../notifications/NotificationsListView";
import {useLocation, useNavigate} from "react-router-dom";
import NotificationDetailView from "../notifications/NotificationDetailView";
import CategoryDetailView from "../data/CategoryDetailView";
import FaqDetailView from "../more/faq/FaqDetailView";

const useStyles = makeStyles((theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            whiteSpace: 'nowrap'
        },
        content: {
            flexGrow: 1,
            paddingTop: theme.spacing(1),
        }
    })))
;

export default function DetailView({action}) {
    const classes = useStyles();
    const navigate = useNavigate()
    const {state} = useLocation()
    const onBackPress = () => {
        navigate(-1)
    }

    return <div className={classes.root}>
        <AppBar position={'static'} color={'inherit'}>
            <Toolbar variant={"dense"}>
                <IconButton onClick={onBackPress} className={classes.menuButton}>
                    <BackIcon/>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {action.title}
                </Typography>
            </Toolbar>
        </AppBar>
        <main className={classes.content}>
            {viewForId(action.id, state)}
        </main>
    </div>
}

const viewForId = (id, data) => {
    if (id === "about") {
        return <AboutUsView/>
    } else if (id === "faqs") {
        return <FaqListView/>
    } else if (id === "notifications") {
        return <NotificationsListView/>
    } else if (id === "notificationDetails") {
        return <NotificationDetailView notification={data}/>
    } else if (id === "faqDetails") {
        return <FaqDetailView faq={data}/>
    } else if (id === "categoryDetails") {
        return <CategoryDetailView category={data}/>
    }
    return <span> </span>
}