import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    ListItemSecondaryAction
} from "@material-ui/core";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {notifications} from "../../services/AppService";
import {TKeys} from "../../i18n/keys";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CategoryIcon from '@material-ui/icons/Category';
import VideoIcon from '@material-ui/icons/Videocam';
import NotificationIcon from "@material-ui/icons/Notifications";
import {useNavigate} from "react-router-dom";
import {logout} from "../../services/LoginApi";
import NextIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function NotificationsListView() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [message, setMessage] = useState("");
    const {t} = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate()
    const [login, setLogin] = useState(false);

    useEffect(() => {
        setLoading(true);
        notifications(data => {
            setLoading(false)
            setList(data)
        }, (error, login) => {
            setLoading(false)
            setMessage(error)
            setLogin(login);
        }, t)
    }, [t,])

    const handleErrorTap = () => {
        if (login) {
            logout()
            navigate("/login")
        }
        setMessage("");
    }

    const onClick = notification => {
        navigate(`/notifications/${notification.id}`, {state: notification})
    }

    return (
        <div className={classes.root}>
            {loading && <CircularProgress/>}
            <List>
                {list.map(it => (
                    <React.Fragment key={it.id}>
                        <ListItem key={it.id}
                                  onClick={() => onClick(it)}
                                  button>
                            <ListItemAvatar>
                                <Avatar>
                                    {getIcon(it)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={it.subject}
                                          secondary={trimmed(it.body, 50)}/>
                            <ListItemSecondaryAction>
                                <NextIcon color={'disabled'}
                                          fontSize={'small'}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant={'inset'} component="li"/>
                    </React.Fragment>
                ))}
            </List>
            <Dialog open={message.length > 1}>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={handleErrorTap}>{t(TKeys.ok)}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function trimmed(text, length) {
    let textLength = text.length;
    if (textLength <= length) {
        return text;
    } else {
        let trimmed = text.substring(0, length)
        return `${trimmed}...`
    }
}

const getIcon = (notification) => {
    if (notification.categoryId > 0) {
        return <CategoryIcon/>
    } else if (notification.videoId > 0) {
        return <VideoIcon/>
    }
    return <NotificationIcon/>
}