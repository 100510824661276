import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {faqs} from "../../../services/AppService";
import ListItem from "@material-ui/core/ListItem";
import NextIcon from "@material-ui/icons/ArrowForwardIos";
import ListItemText from "@material-ui/core/ListItemText";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    ListItemSecondaryAction
} from "@material-ui/core";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {TKeys} from "../../../i18n/keys";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../services/LoginApi";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function FaqListView() {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [message, setMessage] = useState("");
    const {t} = useTranslation();
    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true);
        faqs(data => {
            setLoading(false)
            setList(data)
        }, (error, login) => {
            setLoading(false)
            setMessage(error)
            setLogin(login);
        }, t)
    }, [t])
    const handleErrorTap = () => {
        if (login) {
            logout()
            navigate("/login")
        }
        setMessage("");
    }
    const onClick = faq => {
        navigate(`/faq/${faq.id}`, {state: faq})
    }
    return (
        <div className={classes.root}>
            {loading && <CircularProgress/>}
            {!loading &&
                <List>
                    {list.map(it => (
                        <React.Fragment key={it.id}>
                            <ListItem key={it.id}
                                      button
                                      onClick={() => onClick(it)}>
                                <ListItemText primary={it.question}/>
                                <ListItemSecondaryAction>
                                    <NextIcon fontSize={"small"} color={"disabled"}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li"/>
                        </React.Fragment>
                    ))}
                </List>}
            <Dialog open={message.length > 1}>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={handleErrorTap}>{t(TKeys.ok)}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}