import './App.css';
import {BrowserRouter} from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import {ThemeProvider} from "@material-ui/core/styles";
import {AppTheme} from "./theme";
import React from "react";

function App() {
    return (
        <ThemeProvider theme={AppTheme}>
            <div className="App">
                <BrowserRouter>
                    <AppRoutes/>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
}

export default App;
