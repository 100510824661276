import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {DialogContent} from "@material-ui/core";
import {Viewer, Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        background: 'black',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: 'white'
    },
    icon: {
        color: 'white',
    },
    root: {
        overflowY: 'hidden',
        padding: '0 !important',
    },
    pdf: {
        width: '100vw',
        height: "auto",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PdfViewer({pdf, handleClose, open}) {
    const classes = useStyles();

    return (
        <Dialog fullScreen
                open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} color={'inherit'}>
                <Toolbar>
                    <IconButton edge="start" color="inherit"
                                className={classes.icon}
                                onClick={handleClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.root}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdf.url}/>;
                </Worker>
            </DialogContent>
        </Dialog>
    );
}
