import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Login from "../controller/login/Login";
import Home from "../controller/home/Home";
import DetailView from "../controller/detail/DetailView";
import {useTranslation} from "react-i18next";
import {TKeys} from "../i18n/keys";
import {isSigned} from "../services/LoginApi";

export default function AppRoutes() {
    const {t} = useTranslation();
    return (
        <Routes>
            <Route path="/login" exact element={<Login/>}/>
            <Route path={'/about'} exact
                   element={
                       <RequireAuth>
                           <DetailView
                               action={{title: t(TKeys.aboutUs), id: "about"}}/>
                       </RequireAuth>}/>
            <Route path={'/faqs'} exact
                   element={
                       <RequireAuth>
                           <DetailView
                               action={{title: t(TKeys.faq), id: "faqs"}}/>
                       </RequireAuth>}/>
            <Route path={'/notifications'} exact
                   element={
                       <RequireAuth>
                           <DetailView
                               action={{title: t(TKeys.notifications), id: "notifications"}}/>
                       </RequireAuth>}/>
            <Route path={'/notifications/:id'} exact
                   element={
                       <RequireAuth>
                           <DetailView
                               action={{
                                   title: t(TKeys.notifications),
                                   id: "notificationDetails"
                               }}/>
                       </RequireAuth>}/>
            <Route path={'/faq/:id'} exact
                   element={
                       <RequireAuth>
                           <DetailView
                               action={{
                                   title: t(TKeys.faq),
                                   id: "faqDetails"
                               }}/>
                       </RequireAuth>}/>
            <Route path={'/categories/:id'} exact
                   element={
                       <RequireAuth>
                           <DetailView action={{
                               title: "",
                               id: "categoryDetails"
                           }}/>
                       </RequireAuth>}/>

            <Route path="/" exact element={<RequireAuth><Home/></RequireAuth>}/>
        </Routes>
    );
}

function RequireAuth({children}) {
    let location = useLocation();
    if (!isSigned()) {
        return <Navigate to={"/login"} state={{from: location}} replace/>
    }
    return children

}
