const tr = {
    app_name: "Buğra Hoca Fit Yaşam",
    unknown_error: "Bilinmeyen Hata",
    retry: "Tekrarla",
    search: "Ara",
    ok: "OK",
    welcome: "Hoşgeldiniz",
    login_register_message: "Lütfen devam etmek için giriş yapınız.",
    password: "Şifre",
    login: "Giriş",
    username: "Kullanıcı Adı",
    no_internet_connection: "İnternet bağlantınızı kontrol ediniz.",
    invalid_password: "Şifre yanlış",
    account_expired: "Your account has been disabled.",
    account_not_found: "Bu kullanıcı adı ile hesap mevcut değil. &lt;b&gt;%s&lt;/b&gt;",
    message_home: "Please StayHome",
    version_placeholder: "Versiyon %s (%d)",
    logout: "Çıkış",
    logout_subtitle: "Çıkış yap",
    faq: "FAQ",
    subtitle_faq: "Sık sorulan sorular",
    about_us: "Hakkımda ve iletişim",
    subtitle_about_us: " 💖 ile geliştirildi. ",
    yes: "Evet",
    no: "Hayır",
    logout_message: "Çıkış yapmak istiyormusunuz?",
    category_restricted: "Bu paketi satın almak için bizimle iletişime geçebilirsiniz.",
    ask_admin_reset_device: "Bu cihazdan giriş yapmak için lütfen uygulama sahibi ile iletişime geçiniz.",
    login_expired: "Bu cihazda oturumunuz sona erdi. Lütfen tekrar giriş yapınız.",
    remember_me: "Beni Hatırla",
    age: "Yas",
    cm: "CM",
    kg: "KG",
    select_gender: "Lütfen Cinsiyet Seçiniz",
    _16_0_16_9: "16.0 – 16.9",
    _17_0_18_4: "17.0 – 18.4",
    _18_5_24_9: "18.5 – 24.9",
    _25_0_29_9: "25.0 – 29.9",
    _30_34_9: "30 – 34.9",
    _35_39_9: "35 – 39.9",
    _40: " <![CDATA[ > 40 ]]> ",
    normal_weight: "İdeal Ağırlık",
    bmi: "Vücut Kitle İndeksi",
    bmi_subtitle: "Vücut Kitle İndeksi Hesapla",
    noti_category_channel: "Kategori Bildirimleri",
    noti_video_channel: "Video Bildirimleri",
    noti_other_channel: "Karışık Bildirimler",
    notifications: "Bildirimler",
    notifications_subtitle: "Tüm Bildirimleri Görüntüle",
    weight: "Ağırlığınız",
    height: "Boyunuz",
    active: "Hareketli",
    very_active: "Aktif Spor",
    less_active: "Hareketsiz",
    underweight: "Zayıf",
    normal: "İdeal",
    overweight: "Kilo Fazlalığı",
    difference: "Fark",
    obese_three: "Obezite Derecesi 3",
    obese_two: "Obezite Derecesi 2",
    obese_one: "Obezite Derecesi 1",
    less_16: " <![CDATA[ < 16 ]]> ",
    very_severly_underweight: "İdeal Kilonun Çok Altında",
    severly_underweight: "İdeal Kilonun Altında",
    plus_string: "+%1$.2f",
    minus_string: "%1$.2f",
    noraml_weight_text: "%1$.1f - %2$.1f KG",
    home_tab: "Ana Sayfa",
    sport_tab: "Egzersiz",
    food_tab: "Beslenme",
    more_tab: "Daha Fazla",
    featured_videos: "Featured Videos",
    featured_images: "Featured Images",
    featured_categories: "Featured Categories",
    body_tab: "Beden",
    menu_restricted: "Sistemsel güncellemeler sebebi ile içerikler ana menüye taşınmış olup bu menü tüm kullanıcılara geçici bir süre için kapatılmıştır 🤗🙏",

}
export default tr;