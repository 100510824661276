const en = {
    app_name: "Buğra Hoca Fit Yaşam",
    unknown_error: "Unknown error",
    retry: "Retry",
    search: "Search",
    ok: "OK",
    welcome: "Welcome",
    login_register_message: "Please login to continue",
    password: "Password",
    login: "Login",
    username: "Username",
    no_internet_connection: "Please check your internet connection.",
    invalid_password: "Your entered password is wrong. Please try again with correct one",
    account_expired: "Your account has been disabled.",
    account_not_found: "No account found with username %s",
    message_home: "Please StayHome",
    version_placeholder: "Version %s (%d)",
    logout: "Logout",
    logout_subtitle: "Perform logout and delete data",
    faq: "FAQ",
    subtitle_faq: "Frequently asked questions",
    about_us: "About Us",
    subtitle_about_us: "Developed with 💖 by bullhead",
    yes: "Yes",
    no: "No",
    logout_message: "Do you really want to logout?",
    category_restricted: "Contact with us to allow access to this group",
    ask_admin_reset_device: "Please contact admin to allow login from this device",
    login_expired: "Your login from this device has been expired. Please login again",
    remember_me: "Remember Me",
    weight: "Your Weight",
    height: "Your Height",
    active: "Active",
    very_active: "Very Active",
    less_active: "Less Active",
    age: "Age",
    cm: "CM",
    kg: "KG",
    select_gender: "Please select appropriate image for your gender",
    underweight: "Underweight",
    normal: "Normal",
    overweight: "Overweight",
    difference: "Difference",
    obese_three: "Obese III",
    obese_two: "Obese II",
    obese_one: "Obese I",
    less_16: "<![CDATA[< 16]]>",
    very_severly_underweight: "Very Severely Underweight",
    severly_underweight: "Severely Underweight",
    _16_0_16_9: "16.0 – 16.9",
    _17_0_18_4: "17.0 – 18.4",
    _18_5_24_9: "18.5 – 24.9",
    _25_0_29_9: "25.0 – 29.9",
    _30_34_9: "30 – 34.9",
    _35_39_9: "35 – 39.9",
    _40: "<![CDATA[> 40]]>",
    normal_weight: "Normal Weight",
    plus_string: "+%1$.2f",
    minus_string: "%1$.2f",
    noraml_weight_text: "%1$.1f - %2$.1f KG",
    bmi: "BMI",
    bmi_subtitle: "Calculate your Body Mass Index",
    noti_category_channel: "Category Notifications",
    noti_video_channel: "Video Notifications",
    noti_other_channel: "Miscellaneous Notifications",
    notifications: "Notifications",
    notifications_subtitle: "See all notifications",
    home_tab: "Home",
    sport_tab: "Sport",
    food_tab: "Food",
    more_tab: "More",
    body_tab: "Body",
    featured_videos: "Featured Videos",
    featured_images: "Featured Images",
    featured_categories: "Featured Categories",
    menu_restricted: "This menu is closed for updates. Thank you for your understanding.",
}
export default en;