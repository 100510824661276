import React, {useState} from "react";
import BgVideo from '../../assets/video.mp4';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    makeStyles,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import Logo from '../../assets/logo.png';
import {useTranslation} from "react-i18next";
import {TKeys} from "../../i18n/keys";
import {login} from "../../services/LoginApi";
import BgVideoPlayer from 'react-background-video-player';
import {useNavigate} from "react-router-dom";

const useStyle = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        margin: 0,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
    },
    video: {
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        position: "fixed",
    },
    bottomCard: {
        position: 'fixed',
        margin: '0 auto',
        bottom: 20,
        left: 20,
        right: 20,
        borderRadius: theme.spacing(2),
        maxWidth: '600px',
        zIndex: 101,
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '85%',
    },
    button: {
        borderRadius: '25px',
        height: '50px',
        width: '85%',
        color: "white",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    topDiv: {
        width: '85%',
        margin: '0 auto',
        marginTop: theme.spacing(2),
        zIndex: 100,
    },
    title: {
        color: "white",
        fontWeight: 'bolder',
        fontSize: '30px',
        marginTop: theme.spacing(1),

    },
    subtitle: {
        color: 'white',
    }
}))

export default function Login({...props}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const classes = useStyle();
    const {t} = useTranslation();
    const navigate = useNavigate()

    const onChange = name => event => {
        let value = event.target.value;
        if (name === "username") {
            setUsername(value)
        } else if (name === "password") {
            setPassword(value);
        }
    }
    const onLoginTap = () => {
        if (username.length < 1 || password.length < 1) {
            return;
        }
        setLoading(true);
        login(username, password, () => {
            setLoading(false);
            navigate("/");
        }, message => {
            setLoading(false);
            setError(message)
        }, t)

    }

    return (
        <div className={classes.root}>
            <BgVideoPlayer src={BgVideo}/>
            <div className={classes.topDiv}>
                <img src={Logo} alt={"logo"}/>
                <Typography component={'h1'} className={classes.title}>
                    {t(TKeys.welcome)}
                </Typography>
                <Typography component={'h3'} className={classes.subtitle}>
                    {t(TKeys.loginRegisterMessage)}
                </Typography>
            </div>
            <Paper className={classes.bottomCard}>
                <TextField fullWidth variant={'outlined'}
                           placeholder={t(TKeys.username)}
                           value={username}
                           disabled={loading}
                           id={"username"}
                           onChange={onChange("username")}
                           name={"username"}
                           className={classes.textField}/>
                <TextField fullWidth variant={'outlined'}
                           placeholder={t(TKeys.password)}
                           type={'password'}
                           disabled={loading}
                           onChange={onChange("password")}
                           id={"password"}
                           value={password}
                           name={"password"}
                           inputMode={'password'}
                           className={classes.textField}/>
                <Button className={classes.button}
                        color={'primary'}
                        disableElevation
                        onClick={onLoginTap}
                        disabled={loading}
                        variant={'contained'}>{t(TKeys.login)}
                    {loading && <CircularProgress size={24}/>}
                </Button>
            </Paper>
            <Dialog open={error != null}>
                <DialogContent>
                    <DialogContentText>{error}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setError(null)}
                            variant={'text'}
                            color={'primary'}>{t(TKeys.ok)}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}