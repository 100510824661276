import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import {useTranslation} from "react-i18next";
import {TKeys} from "../../i18n/keys";
import HomeIcon from '../../assets/anasayfa_ic.png';
import BodyIcon from '../../assets/ic_body.png';
import FoodIcon from '../../assets/ic_food.png';
import SportIcon from '../../assets/ic_sport.png';
import MoreIcon from '../../assets/id_more.png';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function BottomNavigationView({value, onChange}) {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            showLabels
            className={classes.root}>
            <BottomNavigationAction value={0} label={t(TKeys.homeTab)} icon={<Icon path={HomeIcon}/>}/>
            <BottomNavigationAction value={1} label={t(TKeys.sportTab)} icon={<Icon path={SportIcon}/>}/>
            <BottomNavigationAction value={2} label={t(TKeys.bodyTab)} icon={<Icon path={BodyIcon}/>}/>
            <BottomNavigationAction value={3} label={t(TKeys.foodTab)} icon={<Icon path={FoodIcon}/>}/>
            <BottomNavigationAction value={4} label={t(TKeys.moreTab)} icon={<Icon path={MoreIcon}/>}/>
        </BottomNavigation>
    );
}

const Icon = ({path}) => (
    <img src={path} width={'30px'}
         alt={'icon'}
         height={'30px'}/>
);
