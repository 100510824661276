import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ReactPlayer from "react-player/youtube";
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import {Fab} from "@material-ui/core";
import {Forward30, Replay30, SlowMotionVideoRounded, SpeedRounded} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        background: 'black',
        zIndex: 1019292
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    icon: {
        color: 'white',
    },
    root: {
        background: 'black',
        height: '70vh',
    },
    rVideo: {
        width: '100%',
        height: '100%',
        overflowX: "hidden",
        overflowY: "hidden",
        pointerEvents: "none",
        touchAction: "none",
    },
    player: {
        width: '100%',
        height: '100%',
        overflowX: "hidden",
        overflowY: "hidden",
        position: "absolute",
        backgroundColor: "#00000055",
        zIndex: 1019291,
        alignContent: 'center',
        alignItems: 'center',
        textAlign: "center",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center"
    },
    overlay: {
        width: '100%',
        height: '100%',
        overflowX: "hidden",
        overflowY: "hidden",
        position: "absolute",
        zIndex: 1019290,
        display: "flex",
        justifyContent: 'right',
    },
    durationContainer: {
        padding: '1em',
        display: "flex",
        marginRight: '10px',
        justifyContent: 'end',
        flexGrow: 1,
    },
    duration: {
        fontWeight: 'bold',
        color: "white",
        fontSize: "12px"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function formatDuration(duration) {
    return new Date(duration * 1000).toISOString().slice(11, 19);
}

export default function VideoPlayer({video, handleClose, open}) {
    const classes = useStyles();
    const ref = useRef();
    const [pause, setPause] = useState(true)
    const [controls, setControls] = useState(true)
    const [duration, setDuration] = useState(0)
    const [rate, setRate] = useState(1)
    const [progress, setProgress] = useState(0)

    const close = () => {
        handleClose();
    }
    return (
        <Dialog fullScreen
                open={open}
                onClose={close}
                TransitionComponent={Transition}>
            <AppBar className={classes.appBar} color={'inherit'}>
                <Toolbar>
                    <IconButton edge="start" color="inherit"
                                className={classes.icon}
                                onClick={close} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <div className={classes.durationContainer}>
                        <span className={classes.duration}>{formatDuration(progress)}/{formatDuration(duration)}</span>
                    </div>
                </Toolbar>
            </AppBar>
            <ReactPlayer url={video.url} width={'100%'}
                         className={classes.rVideo}
                         ref={ref}
                         playbackRate={rate}
                         onDuration={setDuration}
                         onProgress={p => setProgress(p.playedSeconds)}
                         playing={!pause}
                         height={'100%'}/>
            <div onClick={() => setControls(true)} className={classes.overlay}>
                <div className={classes.durationContainer}>
                    <span className={classes.duration}>{formatDuration(progress)}/{formatDuration(duration)}</span>
                </div>
            </div>
            {controls && <div className={classes.player} onClick={() => {
                setControls(false)
            }}>
                <div>
                    <Fab color={"inherit"}
                         size={"small"}
                         style={{margin: '10px'}}
                         onClick={() => {
                             setRate(1)
                         }
                         }>
                        <SlowMotionVideoRounded/>
                    </Fab>
                    <Fab color={"inherit"}
                         size={"medium"}
                         style={{margin: '10px'}}
                         onClick={() => {
                             let to = progress - 30
                             if (to < 0) {
                                 to = 0
                             }
                             ref.current.seekTo(to, 'seconds')
                         }
                         }>
                        <Replay30/>
                    </Fab>
                    <Fab color={"inherit"}
                         size={"large"}
                         style={{margin: '10px'}}
                         onClick={(e) => {
                             setPause(!pause)
                             setControls(false)
                             e.preventDefault()
                         }
                         }>
                        {pause ? <PlayIcon/> : <PauseIcon/>}
                    </Fab>
                    <Fab color={"inherit"}
                         size={"medium"}
                         style={{margin: '10px'}}
                         onClick={() => {
                             let to = progress + 30
                             if (to > duration) {
                                 to = duration - 5
                             }
                             ref.current.seekTo(to, 'seconds')
                         }
                         }>
                        <Forward30/>
                    </Fab>
                    <Fab color={"inherit"}
                         size={"small"}
                         style={{margin: '10px'}}
                         onClick={() => {
                             setRate(1.5)
                         }
                         }>
                        <SpeedRounded/>
                    </Fab>
                </div>
            </div>
            }
        </Dialog>
    );
}
