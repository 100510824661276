import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {TKeys} from "../../i18n/keys";
import {logout} from "../../services/LoginApi";

export default function LogoutDialog({open, onClose, onLogout}) {
    const {t} = useTranslation();
    const doLogout = () => {
        logout();
        onClose();
        onLogout()
    }
    return (
        <Dialog open={open}>
            <DialogTitle>{t(TKeys.logout)}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t(TKeys.logoutMessage)}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={'primary'} onClick={onClose}>{t(TKeys.no)}</Button>
                <Button color={'primary'} onClick={doLogout}>{t(TKeys.yes)}</Button>
            </DialogActions>
        </Dialog>
    )

}