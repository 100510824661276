import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {DialogContent} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        background: 'black',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: 'white'
    },
    icon: {
        color: 'white',
    },
    root: {
        overflowY: 'hidden',
        padding: '0 !important',
    },
    image: {
        width: '100%',
        objectFit: "contain",
        height: "85vh",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageViewer({image, handleClose, open}) {
    const classes = useStyles();
    return (
        <Dialog fullScreen
                open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} color={'inherit'}>
                <Toolbar>
                    <IconButton edge="start" color="inherit"
                                className={classes.icon}
                                onClick={handleClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.root}>
                <div style={{overflowY: "scroll", height: "100%"}}>
                    <img src={image.url}
                         alt={image.name}
                         className={classes.image}/>
                    {image.images && image.images.map((url, index) => (
                        <img src={url} alt={url}
                             className={classes.image}/>))}
                </div>
            </DialogContent>
        </Dialog>
    );
}
