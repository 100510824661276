import {createTheme} from '@material-ui/core/styles';

export const AppTheme = createTheme({
    palette: {
        primary: {
            main: '#30D158',
        },
        secondary: {
            main: '#30D158',
        },
    },
});