import {TKeys} from "../i18n/keys";
import axios from "axios";

const handleError = (status, error, t) => {
    if (status === 401) {
        error(t(TKeys.loginExpired), true);
    } else if (status === 400) {
        error(t(TKeys.menuRestricted))
    } else {
        error(t(TKeys.unknownError))
    }
}

export function homeData(type, success, error, t) {
    get(`/app/home/${type}`, data => {
        if (data.ketchup) {
            success(data.ketchup)
        } else {
            error(t(TKeys.unknownError))
        }
    }, error, t)
}

export function faqs(success, error, t) {
    get(`/app/faqs`, success, error, t)
}

export function notifications(success, error, t) {
    get(`/app/notifications`, success, error, t)
}

export function categoryData(id, success, error, t) {
    get(`/app/videos/${id}`, success, error, t)
}

function get(path, success, error, t) {
    axios.get(path)
        .catch(er => {
            if (er.response) {
                handleError(er.response.status, error, t)
            } else {
                error(t(TKeys.unknownError))
            }
        })
        .then(response => {
            if (!response) {
                return;
            }
            if (response.data) {
                success(response.data);
            } else {
                handleError(response.status, error, t)
            }
        })
}
