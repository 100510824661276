import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {useTranslation} from "react-i18next";
import {TKeys} from "../../i18n/keys";
import {Divider, ListItemSecondaryAction} from "@material-ui/core";
import NextIcon from '@material-ui/icons/ArrowForwardIos'
import FaqIcon from '@material-ui/icons/Help';
import NotificationIcon from '@material-ui/icons/Notifications';
import AboutIcon from '@material-ui/icons/Info';
import LogoutIcon from '@material-ui/icons/ExitToApp'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function MenuListView({onChange}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const list = buildItems(t);
    return (
        <List className={classes.root}>
            {list.map(it => (
                <React.Fragment key={it.id}>
                    <ListItem key={it.id}
                              onClick={() => onChange(it.id)}
                              button>
                        <ListItemAvatar>
                            <Avatar>
                                {it.icon}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={it.title} secondary={it.subtitle}/>
                        {!it.hideNext && <ListItemSecondaryAction>
                            <NextIcon color={'disabled'}
                                      fontSize={'small'}/>
                        </ListItemSecondaryAction>}
                    </ListItem>
                    <Divider variant="inset" component="li"/>
                </React.Fragment>
            ))}

        </List>
    );
}

function buildItems(t) {
    return [{
        title: t(TKeys.faq),
        subtitle: t(TKeys.subtitleFaq),
        icon: <FaqIcon/>,
        id: "faqs"
    }, {
        title: t(TKeys.notifications),
        subtitle: t(TKeys.notificationsSubtitle),
        icon: <NotificationIcon/>,
        id: "notifications"
    }, {
        title: t(TKeys.aboutUs),
        subtitle: t(TKeys.aboutUs),
        icon: <AboutIcon/>,
        id: "about"
    }, {
        title: t(TKeys.logout),
        subtitle: t(TKeys.logoutMessage),
        icon: <LogoutIcon/>,
        hideNext: true,
        id: "logout"
    },]
}
