import React from "react";
import {makeStyles, Paper, Typography} from "@material-ui/core";
import Logo from '../../assets/logo.png'
import {getUser} from "../../services/LoginApi";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        borderRadius: theme.spacing(2),
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    title: {
        fontWeight: 'normal',
        fontSize: '24px',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    logo: {
        width: 180,
        height: 40,
        margin: '0 auto'
    },
    subtitle: {
        width: '100%',
        fontSize: 'small',
    }
}))
export default function ProfileView() {
    const classes = useStyles();
    const user = getUser();
    return (
        <Paper className={classes.root} elevation={0}>
            <img src={Logo} alt={'logo'} className={classes.logo}/>
            <Typography className={classes.title}>
                {user.name}
            </Typography>
            <Typography className={classes.subtitle}>
                {user.username}
            </Typography>
        </Paper>
    )
}