import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import {TKeys} from "../i18n/keys";

export function login(username, password, success, error, t) {
    const deviceId = uuidv4();
    const handleError = status => {
        if (status === 401) {
            error(t(TKeys.invalidPassword));
        } else if (status === 404) {
            error(t(TKeys.accountNotFound).replaceAll("%s", username))
        } else if (status === 417) {
            error(t(TKeys.askAdminResetDevice))
        } else if (status === 403) {
            error(t(TKeys.accountExpired))
        } else {
            error(t(TKeys.unknownError))
        }
    }
    axios.post("/users/login",
        JSON.stringify({username: username, password: password, deviceId}),
        {headers: {'Content-Type': 'application/json'}})
        .catch(er => {
            if (er.response) {
                handleError(er.response.status)
            } else {
                error(t(TKeys.unknownError))
            }
        })
        .then(response => {
            if (!response) {
                return;
            }
            if (response.data) {
                afterLogin(response.data, deviceId);
                success();
            } else {
                handleError(response.status)
            }
        })
}

function afterLogin(user, deviceId) {
    localStorage.setItem("bugra.device", deviceId);
    localStorage.setItem("bugra.user", JSON.stringify(user))
    localStorage.setItem("bugra.token", user.accessToken)
    axios.defaults.headers['Authorization'] = `Bearer ${user.accessToken}`;
}

export function getUser() {
    let json = localStorage.getItem("bugra.user");
    return json != null && JSON.parse(json);
}

export function isSigned() {
    return localStorage.getItem("bugra.token") !== null;
}

export function accessToken() {
    return localStorage.getItem("bugra.token");
}

export function logout() {
    localStorage.clear();
    axios.defaults.headers['Authorization'] = "";
    sessionStorage.clear();
}